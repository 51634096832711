<template>
  <router-link
    :to="{ name: 'document_request', params: { request: link } }"
    class="mt-1 cursor-pointer"
    :active-class="activeClass"
    :class="hoverClass"
    tag="div"
  >
    <small>{{ text }}</small>
  </router-link>
</template>
<script>
export default {
  components: {},
  props: {
    text: {
      Type: String,
      required: true,
    },
    link: {
      Type: String,
      required: false,
      default: "test",
    },
  },
  computed: {
    activeClass: function () {
      if (!this.$store.getters["isDarkMode"]) {
        return "stroke-red text-danger";
      } else {
        return "stroke-red text-primary";
      }
    },
    hoverClass: function () {
      if (!this.$store.getters["isDarkMode"]) {
        return "cursor-hover-light";
      } else {
        return "cursor-hover-dark";
      }
    },
  },
};
</script>
