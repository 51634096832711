<template>
  <container-page>
    <bloc-simple class="flex-shrink-0 mb-3" style="min-width: 350px">
      <left-bar></left-bar>
    </bloc-simple>
    <div class="flex-grow-1">
      <router-view></router-view>
    </div>
  </container-page>
</template>
<script>
import ContainerPage from "@/components/containers/ContainerPage.vue";
import BlocSimple from "@/components/containers/ContainerBloc.vue";
import LeftBar from "@/components/documents/LeftBar.vue";

export default {
  data() {
    return {
      searchText: "",
    };
  },
  components: {
    ContainerPage,
    BlocSimple,
    LeftBar,
  },
};
</script>
